.bannerImage {
    margin: auto;
}

.productCard {
    .No{
        color: #f50057;
    }

    h2 {
        display: block;
        color: #1f1f1f;
        margin-bottom: 50px;
        span {
            padding-bottom: 15px;
            border-bottom: 4px solid #f50057;
        }
    }

    .cover {
        margin-top: 10px;
        width: auto;
        height: 220px;
        border-radius: 5px;
    }
}