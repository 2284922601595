.footer {
    // background-color: rgb(36, 40, 44);
    padding-top: 50px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 70px;
    width: 100%;
    position: relative;
    bottom: 0;
    background-color: #181a1a;
    color: #f5f5f5;
}

.textWhite {
    // color: #ffffff
}

.infoIcon {
    color: #ffffff !important;
    background-color: #33383b !important;
}

@media (min-width: 960px) {
    .footer {
        padding-top: 80px;
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 600px) {
    .footer {
        padding-top: 80px;
        padding-left: 128px;
        padding-right: 128px;
        padding-bottom: 80px;
    }
}

.rowName {
    padding-bottom: 14px;
}

.container {
    border-bottom: 1px solid #333;
    padding-bottom: 15px;
}

.boardButton {
    border: 1px solid #f5f5f5 !important;
    color: #f5f5f5 !important;
    padding: 6px 72.5px !important;
    margin-top: 5px !important;
}

.boardLogo {
    width: 100px;
}

.company {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 15px;
    left: 0;
}

.link {
    text-decoration: none;
    outline: none;
    color: hsla(0, 0%, 100%, 0.5);

    & :hover {
        color: #f50057;
    }
}
