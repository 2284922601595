.header {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87);
    flex-grow: 1;
}

.toolbar {
    color: rgba(0, 0, 0, 0.87);
}

.logo {
    width: auto;
    height: 23px;
}

.link {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
}