.main {
    position: relative;
    text-align: center;

    //background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    background-color: #000;
    color: white;
}

.banner {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 65vh;
    overflow: hidden;
    position: relative;

    // display: flex;
    // justify-content: center;
    // align-items: center; 
    // text-align: center;
}

.video {
    object-fit: cover;
    width: 100%;
    height: 80vh;

    // position: absolute;
    // left: 0px;
    // top: 0;
}

.cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    background-image: url(../../static/images/cover.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.mask {
    width: 100%;
    height: 65vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    color: #f5f5f5;
    flex-flow: column;
}

.logo {
    width: 200px;
    margin-bottom: 30px;
}

.grayBox {
    background-color: #465162;
}

.whiteBox {
    background-color: #f5f5f5;
}

.blackBox {
    background-color: #181a1a;
}

.product {
    overflow: hidden;
    padding-bottom: 100px;
    margin-top: -1px;
    z-index: 9999;
    h1 {
        color: #1e1e1e;
        margin-top: 10px;
        margin-bottom: 50px;
    }
}

.pink {
    color: #f50057;
}

.culture {
    overflow: hidden;
    padding-bottom: 60px;
    h1 {
        color: #f5f5f5;
        margin-top: 50px;
        margin-bottom: 50px;
        border-bottom: 1px #333 solid;
        padding-bottom: 18px;
        span {
            border-bottom: 4px #f50057 solid;
            padding-bottom: 20px;
        }
    }

    .container {
        margin-top: 10px;
        margin-right: 20px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .icon {
        color: #f50057;
        font-size: 50px;
        margin-right: 20px;
    }
}

.brand {
    overflow: hidden;
    padding-bottom: 60px;
    
    h1 {
        color: #1e1e1e;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .logo {
        width: 160px;
        height: 66px;
        text-align: center;
        margin-bottom: 30px;
        line-height: 66px;

        img {
            width: auto;
            max-width: 140px;
            height: auto;
        }
    }
}